import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { Resume } from "pages/employeeResume/types";

type GetResumeData = {
  resume: Resume;
};

type GetResumeVars = {
  resumeId: string;
};

export const GET_RESUME = gql`
  query getResume($resumeId: uuid!) {
    resume: employee_resumes_by_pk(id: $resumeId) {
      id
      employeeId: employee_id
      employeeName: employee_name
      role
      summary
      withCompanyBranding: with_company_branding
      documentName: document_name
      comment
      skills: skills_snapshot
      experiences: experiences_snapshot
      educations: educations_snapshot
      certificates: certificates_snapshot
    }
  }
`;

export const useResume = (
  options?: QueryHookOptions<GetResumeData, GetResumeVars>
) => {
  return useQuery<GetResumeData, GetResumeVars>(GET_RESUME, options);
};
