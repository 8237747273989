import axios, { AxiosError } from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { useCallback } from "react";
import { toast } from "react-toastify";
import useSWRMutation, { MutationFetcher } from "swr/mutation";
import { ProjectDocumentForm, ProjectDocument } from "types/project";

export type CreateProjectDocumentsRequestParams = {
  projectId: string;
};

export type CreateProjectDocumentsRequestArgs = {
  requestQueryParams: CreateProjectDocumentsRequestParams;
  requestBody?: ProjectDocumentForm;
};

export type ProjectDocumentResponse = {
  link: string;
};

export const useCreateProjectDocument = () => {
  const { token } = useAuthContext();
  const createProjectDocuments = useCallback<
    MutationFetcher<ProjectDocumentResponse, string, CreateProjectDocumentsRequestArgs>
  >(
    async (
      url,
      {
        arg: {
          requestBody,
          requestQueryParams: { projectId },
        }
      }
    ) => {
      try {
        const { data } = await axios.post<ProjectDocumentResponse>(
          `${url}/${projectId}`,
          requestBody || {},
          {
            headers: {
              "Authorization": token ? `Bearer ${token}` : "",
              "Content-Type": "application/json",
            },
            transformResponse: (response) => {
              const data: ProjectDocument =
                JSON.parse(response || "");
              return data;
            },
          }
        );
        return data;
      } catch (e) {
        toast.error("Something went wrong.");
        throw new Error((e as AxiosError)?.message);
      }
    },
    [token]
  );

  return useSWRMutation(
    `${process.env.REACT_APP_BACKEND_URL}${API.projectDocuments}`,
    createProjectDocuments,
    { throwOnError: false }
  );
};
