import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type UpdateEmployeeCertificateData = {
  response: { id: string };
};

type UpdateEmployeeCertificateVars = {
  id: string;
  employeeId: string;
  name: string;
  date: string;
};

const UPDATE_EMPLOYEE_CERTIFICATE = gql`
  mutation UpdateEmployeeCertificate(
    $id: uuid!
    $employeeId: uuid!
    $name: String!
    $date: timestamptz!
  ) {
    response: update_employee_certificates_by_pk(
      pk_columns: { id: $id }
      _set: { employee_id: $employeeId, name: $name, date: $date }
    ) {
      id
    }
  }
`;

export const useUpdateEmployeeCertificate = (
  options?: MutationHookOptions<
    UpdateEmployeeCertificateData,
    UpdateEmployeeCertificateVars
  >
) => {
  const [updateEmployeeCertificateMutation, data] = useMutation<
    UpdateEmployeeCertificateData,
    UpdateEmployeeCertificateVars
  >(UPDATE_EMPLOYEE_CERTIFICATE, options);

  const updateEmployeeCertificate = useCallback(
    async (values: UpdateEmployeeCertificateVars) => {
      try {
        await updateEmployeeCertificateMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_DETAILS,
              variables: { employeeId: values.employeeId },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [updateEmployeeCertificateMutation]
  );

  return { updateEmployeeCertificate, ...data };
};
