import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type CreateEmployeeExperienceData = {
  response: { id: string };
};

type CreateEmployeeExperienceVars = {
  employeeId: string;
  position: string;
  grade: string;
  company: string;
  project: string;
  startDate: string;
  endDate: string;
  description?: string;
  technologies: string[];
};

const CREATE_EMPLOYEE_EXPERIENCE = gql`
  mutation CreateEmployeeExperience(
    $employeeId: uuid!
    $company: String!
    $position: String!
    $project: String!
    $grade: String!
    $startDate: timestamptz!
    $endDate: timestamptz
    $technologies: jsonb!
    $description: String
  ) {
    response: insert_employee_experiences_one(
      object: {
        employee_id: $employeeId
        company: $company
        position: $position
        project: $project
        grade: $grade
        start_date: $startDate
        end_date: $endDate
        description: $description
        technologies: $technologies
      }
    ) {
      id
    }
  }
`;

export const useCreateEmployeeExperience = (
  options?: MutationHookOptions<
    CreateEmployeeExperienceData,
    CreateEmployeeExperienceVars
  >
) => {
  const [createEmployeeExperienceMutation, data] = useMutation<
    CreateEmployeeExperienceData,
    CreateEmployeeExperienceVars
  >(CREATE_EMPLOYEE_EXPERIENCE, options);

  const createEmployeeExperience = useCallback(
    async (values: CreateEmployeeExperienceVars) => {
      try {
        await createEmployeeExperienceMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_DETAILS,
              variables: { employeeId: values.employeeId },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [createEmployeeExperienceMutation]
  );

  return { createEmployeeExperience, ...data };
};
