import { Button } from "components";
import { USER_ROLES_ENUM } from "config/constants";
import { GIT_COMMIT_HASH } from "config/git";
import ROUTES from "config/routes";
import { VIEWS } from "config/views";
import { useAuthContext } from "contexts";
import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Arrow2Icon } from "resources/icons";
import { MenuItem } from "../menuItem/MenuItem";
import styles from "./SideBarContent.styles";

type SideBarContentProps = {
  setSidebarOpen: (value: boolean) => void;
  isMobile: boolean;
};

export const SideBarContent: FC<SideBarContentProps> = ({
  setSidebarOpen,
  isMobile,
}) => {
  const { pathname } = useLocation();
  const { user, isManager: getIsManager } = useAuthContext();
  const isManager = getIsManager();

  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  const fixedPath = pathname
    .split("/")
    .map((item: string) => item.replace(regexExp, ":employeeId"))
    .join("/");

  return (
    <div className="flex h-screen w-full flex-col bg-purple-2">
      <div className="flex items-center justify-between gap-2 px-5 py-4.5">
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        <Link
          className="grow md:grow-0"
          to={
            isManager
              ? ROUTES.manager.careerPath
              : ROUTES.employee.timesheetsDetails
          }
        >
          <h2 className={styles.title}>
            {isManager ? "MANAGER" : "EMPLOYEE"} PORTAL
          </h2>
        </Link>
        <Button
          className={styles.arrowButton}
          onClick={() => setSidebarOpen(false)}
          aria-label="close sidebar"
        >
          <Arrow2Icon className="w-5" />
        </Button>
      </div>
      <div className={styles.container}>
        <ul className="space-y-2">
          {Object.values(
            VIEWS[
              isManager ? USER_ROLES_ENUM.MANAGER : USER_ROLES_ENUM.EMPLOYEE
            ]
          )
            .filter(({ sidebarVisible }) => !!sidebarVisible)
            .map(({ name, path, icon }) => (
              <MenuItem
                name={name}
                path={path}
                key={name}
                isActive={fixedPath.includes(path)}
                icon={icon}
                userId={user?.userId}
                action={isMobile ? () => setSidebarOpen(false) : null}
              />
            ))}
        </ul>
      </div>
      <div className="mx-auto mb-3 mt-auto text-xs text-grey-3">
        <span>Version: {GIT_COMMIT_HASH}</span>
      </div>
    </div>
  );
};
