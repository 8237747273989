import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type DeleteEmployeeCertificateData = {
  response: { id: string };
};

type DeleteEmployeeCertificateVars = {
  id: string;
};

const DELETE_EMPLOYEE_CERTIFICATE = gql`
  mutation DeleteEmpoloyeeCertificate($id: uuid!) {
    response: delete_employee_certificates_by_pk(id: $id) {
      id
    }
  }
`;

export const useDeleteEmployeeCertificate = (
  employeeId: string,
  options?: MutationHookOptions<
    DeleteEmployeeCertificateData,
    DeleteEmployeeCertificateVars
  >
) => {
  const [deleteEmployeeCertificateMutation, data] = useMutation<
    DeleteEmployeeCertificateData,
    DeleteEmployeeCertificateVars
  >(DELETE_EMPLOYEE_CERTIFICATE, options);

  const deleteEmployeeCertificate = useCallback(
    async (values: DeleteEmployeeCertificateVars) => {
      try {
        await deleteEmployeeCertificateMutation({
          variables: values,
          refetchQueries: [
            { query: GET_EMPLOYEE_DETAILS, variables: { employeeId } },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [deleteEmployeeCertificateMutation, employeeId]
  );

  return { deleteEmployeeCertificate, ...data };
};
