import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_SKILL_ASSESSMENTS } from "hooks";

type CreateSkillAssessmentData = {
  response: { skill: string };
};

type CreateSkillAssessmentVars = {
  employeeId: string;
  skill: string;
  proficiency: number;
};

const CREATE_SKILL_ASSESSMENT = gql`
  mutation CreateSkillAssessment(
    $employeeId: uuid!
    $skill: String!
    $proficiency: Int!
  ) {
    response: insert_employee_skill_assessments_one(
      object: {
        employee_id: $employeeId
        skill: $skill
        proficiency: $proficiency
      }
    ) {
      skill
    }
  }
`;

export const useCreateSkillAssessment = (
  options?: MutationHookOptions<
    CreateSkillAssessmentData,
    CreateSkillAssessmentVars
  >
) => {
  const [createSkillAssessmentMutation, data] = useMutation<
    CreateSkillAssessmentData,
    CreateSkillAssessmentVars
  >(CREATE_SKILL_ASSESSMENT, {
    refetchQueries: [{ query: GET_SKILL_ASSESSMENTS }],
    ...options,
  });

  const createSkillAssessment = useCallback(
    async (values: CreateSkillAssessmentVars) => {
      try {
        await createSkillAssessmentMutation({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [createSkillAssessmentMutation]
  );

  return { createSkillAssessment, ...data };
};
