import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_SKILL_ASSESSMENTS } from "hooks";

type UpdateSkillAssessmentData = {
  response: { skill: string };
};

type UpdateSkillAssessmentVars = {
  employeeId: string;
  skill: string;
  proficiency: number;
};

const UPDATE_SKILL_ASSESSMENT = gql`
  mutation assessment($employeeId: uuid!, $skill: String!, $proficiency: Int!) {
    update_employee_skill_assessments_by_pk(
      pk_columns: { employee_id: $employeeId, skill: $skill }
      _set: { proficiency: $proficiency }
    ) {
      skill
    }
  }
`;

export const useUpdateSkillAssessment = (
  options?: MutationHookOptions<
    UpdateSkillAssessmentData,
    UpdateSkillAssessmentVars
  >
) => {
  const [updateSkillAssessmentMutation, data] = useMutation<
    UpdateSkillAssessmentData,
    UpdateSkillAssessmentVars
  >(UPDATE_SKILL_ASSESSMENT, {
    refetchQueries: [{ query: GET_SKILL_ASSESSMENTS }],
    ...options,
  });

  const updateSkillAssessment = useCallback(
    async (values: UpdateSkillAssessmentVars) => {
      try {
        await updateSkillAssessmentMutation({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [updateSkillAssessmentMutation]
  );

  return { updateSkillAssessment, ...data };
};
