import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { useMemo } from "react";

type GetSkillAssessmentsData = {
  assessments: { employeeId: string; skill: string; proficiency: number }[];
};

type HookType = QueryResult<GetSkillAssessmentsData> & {
  data: GetSkillAssessmentsData;
};

export const GET_SKILL_ASSESSMENTS = gql`
  query getSkillAssessments {
    assessments: employee_skill_assessments {
      skill
      proficiency
    }
  }
`;

export const useSkillAssessments = (
  options?: QueryHookOptions<GetSkillAssessmentsData>
): HookType => {
  const { data, ...rest } = useQuery<GetSkillAssessmentsData>(
    GET_SKILL_ASSESSMENTS,
    options
  );

  const parsedData = useMemo<GetSkillAssessmentsData>(
    () => ({
      assessments: data?.assessments ?? [],
    }),
    [data]
  );

  return { data: parsedData, ...rest };
};
