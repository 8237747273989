import { useCallback } from "react";
import axios, { AxiosError } from "axios";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { toast } from "react-toastify";
import API from "config/api";
import { SkillAssessment } from "types/skills";
import { useAuthContext } from "contexts";

export const useSyncEmployeeSkillsAssessment = (employeeId: string) => {
  const { token } = useAuthContext();
  const syncSkillsAssessment = useCallback(
    async (url: string) => {
      try {
        const { data } = await axios.get(url, {
          headers: {
            "Authorization": token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
          },
          transformResponse: (response) => {
            const data: SkillAssessment[] = JSON.parse(response || null);
            return data ?? [];
          },
        });
        await mutate(
          `${process.env.REACT_APP_BACKEND_URL}${API.employeeSkillsAssessment}/${employeeId}`
        );
        return data;
      } catch (ex) {
        toast.error("Something went wrong.");
        throw new Error((ex as AxiosError)?.message);
      }
    },
    [employeeId, token]
  );

  return useSWRMutation(
    `${process.env.REACT_APP_BACKEND_URL}${API.employeeSkillsAssessment}/${employeeId}?syncWithGdrive=true`,
    syncSkillsAssessment,
    { throwOnError: false }
  );
};
