import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type UpdateEmployeeExperienceData = {
  response: { id: string };
};

type UpdateEmployeeExperienceVars = {
  id: string;
  employeeId: string;
  position: string;
  grade: string;
  company: string;
  project: string;
  startDate: string;
  endDate: string;
  description?: string;
  technologies: string[];
};

const UPDATE_EMPLOYEE_EXPERIENCE = gql`
  mutation UpdateEmployeeExperience(
    $id: uuid!
    $employeeId: uuid!
    $company: String!
    $position: String!
    $project: String!
    $grade: String!
    $startDate: timestamptz!
    $endDate: timestamptz
    $technologies: jsonb!
    $description: String
  ) {
    response: update_employee_experiences_by_pk(
      pk_columns: { id: $id }
      _set: {
        employee_id: $employeeId
        company: $company
        position: $position
        project: $project
        grade: $grade
        start_date: $startDate
        end_date: $endDate
        description: $description
        technologies: $technologies
      }
    ) {
      id
    }
  }
`;

export const useUpdateEmployeeExperience = (
  options?: MutationHookOptions<
    UpdateEmployeeExperienceData,
    UpdateEmployeeExperienceVars
  >
) => {
  const [updateEmployeeExperienceMutation, data] = useMutation<
    UpdateEmployeeExperienceData,
    UpdateEmployeeExperienceVars
  >(UPDATE_EMPLOYEE_EXPERIENCE, options);

  const updateEmployeeExperience = useCallback(
    async (values: UpdateEmployeeExperienceVars) => {
      try {
        await updateEmployeeExperienceMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_DETAILS,
              variables: { employeeId: values.employeeId },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [updateEmployeeExperienceMutation]
  );

  return { updateEmployeeExperience, ...data };
};
