import { useCallback } from "react";
import axios from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { Resume } from "pages/employeeResume/types";

export const useUpsertResume = (employeeId: string) => {
  const { token } = useAuthContext();

  const upsertResume = useCallback(
    (resume: Resume, pdf: Blob) => {
      const formData = new FormData();
      const file = new File([pdf], resume.documentName);
      if (resume.id) formData.append("id", resume.id);
      formData.append("employee_id", resume.employeeId);
      formData.append("employee_name", resume.employeeName);
      formData.append("role", resume.role);
      if (resume.summary) formData.append("summary", resume.summary);
      formData.append(
        "with_company_branding",
        resume.withCompanyBranding ? "true" : "false"
      );
      formData.append("document_name", resume.documentName);
      if (resume.comment) formData.append("comment", resume.comment);
      formData.append("skills_snapshot", JSON.stringify(resume.skills));
      formData.append(
        "experiences_snapshot",
        JSON.stringify(resume.experiences)
      );
      formData.append("educations_snapshot", JSON.stringify(resume.educations));
      formData.append(
        "certificates_snapshot",
        JSON.stringify(resume.certificates)
      );
      formData.append("file", file);

      return axios.post(
        `${process.env.REACT_APP_BACKEND_URL}${API.employeeResumeUpload}/${employeeId}`,
        formData,
        {
          headers: {
            "Authorization": token ? `Bearer ${token}` : "",
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
        }
      );
    },
    [token, employeeId]
  );

  return { upsertResume };
};
