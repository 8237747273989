import { Button } from "components";
import ROUTES from "config/routes";
import {
  AnswersGroupedByQuestionIdType,
  CurrentSubcategory,
  QuestionsByCategoryAndSubcategory,
} from "pages/managerCareerPathForm/hooks";
import React, { Dispatch, FC, SetStateAction } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Arrow4Icon } from "resources/icons";
import {
  CHANGE_QUESTIONS_SUBCATEGORY,
  useQuestionsFooter,
} from "./useQuestionsFooter";

export type QuestionsFooterProps = {
  currentSubCategory: CurrentSubcategory;
  answersGroupedByQuestionIdState: AnswersGroupedByQuestionIdType;
  setCurrentSubCategory: Dispatch<SetStateAction<CurrentSubcategory>>;
  mappedSubcategories?: QuestionsByCategoryAndSubcategory;
  isFormCompleted: boolean;
  isLastSubcategory: boolean;
  lastStepButtonText?: string;
  to?: LinkProps["to"];
  isEvaluationSurveyPreview?: boolean;
};

export const QuestionsFooter: FC<QuestionsFooterProps> = ({
  isFormCompleted,
  isLastSubcategory,
  lastStepButtonText = "Complete",
  to,
  isEvaluationSurveyPreview = false,
  ...restProps
}) => {
  const {
    disableGoToPreviousQuestions,
    disableGoToNextQuestions,
    handleChangeQuestionsSubcategory,
  } = useQuestionsFooter(restProps);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  return (
    <div className="flex justify-between gap-3 bg-white px-12 py-5 shadow-[0px_-8px_8px_-6px] shadow-grey-5 xs:flex-col xs:px-5">
      {isEvaluationSurveyPreview ? (
        <div />
      ) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Link to={ROUTES.manager.careerPath} className="flex">
          <Button
            variant="tertiary"
            disabled={isFormCompleted}
            className="xs:w-full"
          >
            Finish later
          </Button>
        </Link>
      )}
      <div className="flex gap-3">
        <Button
          variant="secondary"
          disabled={disableGoToPreviousQuestions}
          className="flex h-full items-center rounded bg-grey-6 p-3 hover:bg-grey-4 disabled:bg-grey-6 disabled:text-grey-2"
          onClick={() => {
            handleChangeQuestionsSubcategory(
              CHANGE_QUESTIONS_SUBCATEGORY.PREVIOUS
            );
            scrollToTop();
          }}
        >
          <Arrow4Icon />
        </Button>
        {isLastSubcategory ? (
          <>
            {!isEvaluationSurveyPreview && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Link
                to={to || ROUTES.manager.careerPath}
                className="flex xs:grow"
              >
                <Button
                  variant="primary"
                  disabled={!isFormCompleted}
                  className="w-60 xs:grow"
                  onClick={scrollToTop}
                >
                  {lastStepButtonText}
                </Button>
              </Link>
            )}
          </>
        ) : (
          <Button
            variant="primary"
            disabled={disableGoToNextQuestions}
            className="w-60 xs:grow"
            onClick={() => {
              handleChangeQuestionsSubcategory(
                CHANGE_QUESTIONS_SUBCATEGORY.NEXT
              );
              scrollToTop();
            }}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};
