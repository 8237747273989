import axios, { AxiosError } from "axios";
import { GOOGLE_CALENDAR_URL } from "config/constants";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { HolidayEvent } from "types/timesheets";

export type GetHolidaysData = {
  items: HolidayEvent[];
};

type UseGetYearHolidaysProps = { date: string };

export const useGetYearHolidays = ({ date }: UseGetYearHolidaysProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [data, setData] = useState<HolidayEvent[]>([]);
  const year = useMemo(
    () => `${moment(date || new Date()).year()}-01-01`,
    [date]
  );

  useEffect(() => {
    const controller = new AbortController();
    const getHolidays = async () => {
      setLoading(true);
      try {
        // google api returns holidays only for previous, current and next year
        const holidays = await axios.get<HolidayEvent[]>(GOOGLE_CALENDAR_URL, {
          headers: {
            Authorization: undefined,
          },
          params: {
            timeMin: moment(year).startOf("year").startOf("week").toISOString(),
            timeMax: moment(year).endOf("year").endOf("week").toISOString(),
          },
          signal: controller.signal,
          transformResponse: (response) => {
            const data: GetHolidaysData = JSON.parse(response);
            setError(undefined);
            return data?.items
              ?.map(({ id, description, summary, start, end }) => ({
                id,
                description,
                summary,
                start,
                end,
              }))
              ?.filter(({ description }) => description === "Public holiday");
          },
        });
        setData(holidays?.data || []);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setLoading(false);
      }
    };
    getHolidays();
    //on local development you need to disable strict mode when you want to work with that hook
    return () => controller.abort();
  }, [year]);
  return { data, loading, error };
};
