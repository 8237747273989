import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type CreateEmployeeEducationData = {
  response: { id: string };
};

type CreateEmployeeEducationVars = {
  employeeId: string;
  schoolName: string;
  fieldOfStudy: string;
  startDate: string;
  endDate: string;
};

const CREATE_EMPLOYEE_EDUCATION = gql`
  mutation CreateEmployeeEducation(
    $employeeId: uuid!
    $schoolName: String!
    $fieldOfStudy: String!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    response: insert_employee_educations_one(
      object: {
        employee_id: $employeeId
        school_name: $schoolName
        field_of_study: $fieldOfStudy
        start_date: $startDate
        end_date: $endDate
      }
    ) {
      id
    }
  }
`;

export const useCreateEmployeeEducation = (
  options?: MutationHookOptions<
    CreateEmployeeEducationData,
    CreateEmployeeEducationVars
  >
) => {
  const [createEmployeeEducationMutation, data] = useMutation<
    CreateEmployeeEducationData,
    CreateEmployeeEducationVars
  >(CREATE_EMPLOYEE_EDUCATION, options);

  const createEmployeeEducation = useCallback(
    async (values: CreateEmployeeEducationVars) => {
      try {
        await createEmployeeEducationMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_DETAILS,
              variables: { employeeId: values.employeeId },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [createEmployeeEducationMutation]
  );

  return { createEmployeeEducation, ...data };
};
