import { useCallback } from "react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import useSWR, { Fetcher } from "swr";
import API from "config/api";
import { SkillAssessment } from "types/skills";
import { useAuthContext } from "contexts";

export const useGetEmployeeSkillsAssessment = (employeeId: string) => {
  const { token } = useAuthContext();

  const getSkillsAssessment = useCallback<Fetcher<SkillAssessment[], string>>(
    async (url) => {
      try {
        const { data } = await axios.get(url, {
          headers: {
            "Authorization": token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
          },
          transformResponse: (response) => {
            const data: SkillAssessment[] = JSON.parse(response || null);
            return data ?? [];
          },
        });
        return data;
      } catch (ex) {
        toast.error("Something went wrong.");
        throw new Error((ex as AxiosError)?.message);
      }
    },
    [token]
  );

  return useSWR(
    `${process.env.REACT_APP_BACKEND_URL}${API.employeeSkillsAssessment}/${employeeId}`,
    getSkillsAssessment,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};
