import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type UpdateEmployeeEducationData = {
  response: { id: string };
};

type UpdateEmployeeEducationVars = {
  id: string;
  employeeId: string;
  schoolName: string;
  fieldOfStudy: string;
  startDate: string;
  endDate: string;
};

const UPDATE_EMPLOYEE_EDUCATION = gql`
  mutation UpdateEmployeeEducation(
    $id: uuid!
    $employeeId: uuid!
    $schoolName: String!
    $fieldOfStudy: String!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    response: update_employee_educations_by_pk(
      pk_columns: { id: $id }
      _set: {
        employee_id: $employeeId
        school_name: $schoolName
        field_of_study: $fieldOfStudy
        start_date: $startDate
        end_date: $endDate
      }
    ) {
      id
    }
  }
`;

export const useUpdateEmployeeEducation = (
  options?: MutationHookOptions<
    UpdateEmployeeEducationData,
    UpdateEmployeeEducationVars
  >
) => {
  const [updateEmployeeEducationMutation, data] = useMutation<
    UpdateEmployeeEducationData,
    UpdateEmployeeEducationVars
  >(UPDATE_EMPLOYEE_EDUCATION, options);

  const updateEmployeeEducation = useCallback(
    async (values: UpdateEmployeeEducationVars) => {
      try {
        await updateEmployeeEducationMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_DETAILS,
              variables: { employeeId: values.employeeId },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [updateEmployeeEducationMutation]
  );

  return { updateEmployeeEducation, ...data };
};
