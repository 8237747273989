import moment from "moment";
import { TimeRange, Timesheet } from "types/timesheets";

export const getWeeksForMonth = (
  timesheets: Timesheet[],
  activeMonth: TimeRange
) => {
  const startOfMonth = moment(activeMonth.start);
  const endOfMonth = moment(activeMonth.end);

  const weeks: Timesheet[][][] = [];
  let daysOfCurrentWeek: Timesheet[][] = [];

  for (let day = startOfMonth.date(); day <= endOfMonth.date(); day++) {
    const todaysTimesheets = timesheets
      .filter((timesheet) => moment(timesheet.startTime).date() === day)
      .toSorted((timesheet1, timesheet2) =>
        timesheet1.startTime.localeCompare(timesheet2.startTime)
      );

    daysOfCurrentWeek.push(todaysTimesheets);

    // if we reached the end of week or month, finish the current week
    if (startOfMonth.date(day).day() === 0 || day === endOfMonth.date()) {
      weeks.push(daysOfCurrentWeek);
      daysOfCurrentWeek = [];
    }
  }

  return weeks;
};
