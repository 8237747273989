import { ApolloError } from "@apollo/client";
import { useAuthContext } from "contexts";
import {
  GetTimesheetsEmployeeData,
  useGetEmployeeCalendar,
  useGetEmployeeWeeklyTimesheets,
  useURLSearchParams,
} from "hooks";
import moment from "moment";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { ProjectEmployeeAssignment } from "types/assignments";
import { CONTRACTS_WORK_TIME_ENUM } from "types/employee";
import {
  TimeRange,
  HolidayEvent,
  Timesheet,
  TimesheetExceptionDay,
  TimesheetsDayStatus,
  TimesheetsDayVariant,
} from "types/timesheets";
import {
  checkExceptionDay,
  getTimesheetsBetweenDates,
  getTimesheetsDayStatus,
  getTimesheetsDayVariant,
  getWeekDatesRange,
} from "utils/timesheets.utils";
import { useTimeSheetsContext } from "../timesheets/Timesheets.context";
import { getWeeksForMonth } from "pages/timesheets/components/calendar/components/monthCalendar/utils";

type ChangeActiveWeekType = (date: string) => void;
type ChangeActiveMonthType = (month: number) => void;

type TimesheetsWeekContextType = {
  activeSecondaryCalendar: "year" | "month";
  setActiveSecondaryCalendar: React.Dispatch<
    React.SetStateAction<"year" | "month">
  >;
  error?: ApolloError;
  loading: boolean;
  activeWeek: TimeRange;
  activeMonth: TimeRange;
  changeActiveWeek: ChangeActiveWeekType;
  changeActiveMonth: ChangeActiveMonthType;
  monthlyData: GetTimesheetsEmployeeData | undefined;
  setMonthlyData: React.Dispatch<
    React.SetStateAction<GetTimesheetsEmployeeData | undefined>
  >;
  employeeId: string;
  activeWeekData: Record<
    string, // date
    {
      dayStatus: TimesheetsDayStatus;
      dayVariant: TimesheetsDayVariant;
      dayTimesheets: Timesheet[];
      holiday: HolidayEvent | undefined;
    }
  >;
  activeMonthData: {
    dateMoment: moment.Moment;
    dayStatus: TimesheetsDayStatus;
    dayVariant: TimesheetsDayVariant;
    timesheets: Timesheet[];
    isException: boolean;
  }[][];
  activeWeekTimesheets: Timesheet[];
  activeWeekAssignments: ProjectEmployeeAssignment[];
  weekDates: string[];
  activeYear: number;
  employeeContractWorkTime: CONTRACTS_WORK_TIME_ENUM | undefined;
  exceptionDays: TimesheetExceptionDay[];
};

const TimeSheetsWeekContext = createContext<TimesheetsWeekContextType>(
  {} as TimesheetsWeekContextType
);

export const TimeSheetsWeekContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const history = useHistory();
  const { holidaysData } = useTimeSheetsContext();
  const { user, isManager } = useAuthContext();
  const { projectId } = useParams<{ projectId?: string }>();
  const { searchParams } = useURLSearchParams();

  const [activeSecondaryCalendar, setActiveSecondaryCalendar] = useState<
    "year" | "month"
  >("year");

  const employeeId = isManager()
    ? searchParams.get("employeeId") || ""
    : user?.userId ?? "";
  const startDateParam = searchParams.get("startDate") || undefined;

  // default: current week
  const [activeWeek, setActiveWeek] = useState<TimeRange>(() => {
    return getWeekDatesRange(startDateParam);
  });
  const activeMonth = useMemo(() => {
    const startOfWeek = moment(activeWeek.start);
    return {
      start: startOfWeek.startOf("month").toISOString(),
      end: startOfWeek.endOf("month").toISOString(),
    };
  }, [activeWeek.start]);
  const [activeYear, setActiveYear] = useState<number>(
    moment(startDateParam).year()
  );

  useEffect(() => {
    const dates = getWeekDatesRange(startDateParam);
    if (activeWeek.start !== dates.start) setActiveWeek(dates);
  }, [activeWeek.start, startDateParam]);

  const {
    data: employeeData,
    loading: employeeLoading,
    error: employeeError,
  } = useGetEmployeeWeeklyTimesheets({ employeeId, timeRange: activeWeek });

  // monthly view isn't always visible, so don't query it unless needed
  const [monthlyData, setMonthlyData] = useState<GetTimesheetsEmployeeData>();

  const { refetch: refetchCalendar } = useGetEmployeeCalendar({
    employeeId,
    year: activeYear,
  });

  const changeActiveWeek = useCallback<ChangeActiveWeekType>(
    (clickedDate) => {
      const { start, end } = getWeekDatesRange(clickedDate);
      setActiveWeek({ start, end });
      searchParams.set("startDate", start);
      history.push({ search: `?${searchParams}` });
      const year = moment(start).year();
      if (year !== activeYear) {
        year !== activeYear && setActiveYear(year);
        const startDate = moment([year]).startOf("year").format("YYYY-MM-DD");
        const endDate = moment([year]).endOf("year").format("YYYY-MM-DD");
        refetchCalendar({ employeeId, startDate, endDate });
      }
    },
    [activeYear, searchParams, history, employeeId, refetchCalendar]
  );

  const changeActiveMonth = useCallback(
    (month: number) => {
      const start = moment(activeMonth.start);
      const newMonthStart = start.month(month);

      // next Monday
      const newWeekStart = newMonthStart.day(8);
      // if the Monday isn't the first, go back by a week
      if (newWeekStart.date() > 7) newWeekStart.subtract(1, "week");

      changeActiveWeek(newWeekStart.toISOString());
    },
    [activeMonth.start, changeActiveWeek]
  );

  const weekDates = useMemo(() => {
    const dates: string[] = [];
    for (
      let i = 0;
      i <= moment(activeWeek.end).diff(moment(activeWeek.start), "days");
      i++
    ) {
      dates.push(moment(activeWeek.start).add(i, "days").toISOString());
    }
    return dates;
  }, [activeWeek]);

  const activeWeekAssignments = useMemo(() => {
    const assignments = employeeData?.assignments ?? [];
    const dummyAssignment = [
      // dummy assignment needed to show empty row with day-offs and holidays for weeks without any assignment
      {
        id: "",
        project: { id: "" },
        employee: { id: "" },
        startDate: activeWeek.start,
        endDate: activeWeek.end,
      } as ProjectEmployeeAssignment,
    ];

    return assignments.length > 0
      ? projectId
        ? [...assignments].sort(({ project }) =>
            project.id === projectId ? -1 : 0
          )
        : assignments
      : dummyAssignment;
  }, [activeWeek, employeeData?.assignments, projectId]);

  const activeWeekData = useMemo(() => {
    return weekDates.reduce((acc, date) => {
      const endOfDay = moment(date).endOf("day").toISOString();

      const dayTimesheets = getTimesheetsBetweenDates(
        employeeData?.timesheets ?? [],
        date,
        endOfDay
      );
      const holiday = holidaysData.find((h) =>
        moment(h.start.date).isSame(date)
      );
      const dayVariant = getTimesheetsDayVariant(date, dayTimesheets, holiday);
      const dayStatus = getTimesheetsDayStatus(dayTimesheets);
      return {
        ...acc,
        [date]: { dayStatus, dayVariant, dayTimesheets, holiday },
      };
    }, {});
  }, [employeeData?.timesheets, holidaysData, weekDates]);

  const activeMonthData = useMemo(() => {
    if (!monthlyData?.timesheets) return [];

    const dailyTimesheets = getWeeksForMonth(
      monthlyData?.timesheets,
      activeMonth
    );

    let date = 0;

    return dailyTimesheets.map((weeklyTimesheets) =>
      weeklyTimesheets.map((timesheets) => {
        date++;

        const dateMoment = moment(activeMonth.start).date(date);
        const holiday = holidaysData.find((holiday) =>
          dateMoment.isSame(holiday.start.date, "day")
        );

        const dayVariant = getTimesheetsDayVariant(
          dateMoment.toISOString(),
          timesheets,
          holiday
        );
        const dayStatus = getTimesheetsDayStatus(timesheets);
        const isException = !!checkExceptionDay(
          dateMoment,
          monthlyData?.exceptionDays
        ).exceptionDay?.enabled;

        return {
          dateMoment,
          dayStatus,
          dayVariant,
          timesheets,
          isException,
        };
      })
    );
  }, [
    monthlyData?.timesheets,
    monthlyData?.exceptionDays,
    holidaysData,
    activeMonth,
  ]);

  return (
    <TimeSheetsWeekContext.Provider
      value={{
        activeSecondaryCalendar,
        setActiveSecondaryCalendar,
        activeWeek,
        changeActiveWeek,
        changeActiveMonth,
        activeWeekData,
        activeWeekTimesheets: employeeData?.timesheets ?? [],
        activeWeekAssignments,
        activeMonth,
        monthlyData,
        setMonthlyData,
        activeMonthData,
        loading: employeeLoading,
        error: employeeError,
        weekDates,
        employeeId,
        activeYear,
        employeeContractWorkTime: employeeData?.employee?.contracts?.length
          ? employeeData?.employee.contracts[0].workTime
          : undefined,
        exceptionDays: employeeData?.exceptionDays ?? [],
      }}
    >
      {children}
    </TimeSheetsWeekContext.Provider>
  );
};

export const useTimeSheetsWeekContext = () => useContext(TimeSheetsWeekContext);
