import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type DeleteEmployeeEducationData = {
  response: { id: string };
};

type DeleteEmployeeEducationVars = {
  id: string;
};

const DELETE_EMPLOYEE_EDUCATION = gql`
  mutation DeleteEmpoloyeeEducation($id: uuid!) {
    response: delete_employee_educations_by_pk(id: $id) {
      id
    }
  }
`;

export const useDeleteEmployeeEducation = (
  employeeId: string,
  options?: MutationHookOptions<
    DeleteEmployeeEducationData,
    DeleteEmployeeEducationVars
  >
) => {
  const [deleteEmployeeEducationMutation, data] = useMutation<
    DeleteEmployeeEducationData,
    DeleteEmployeeEducationVars
  >(DELETE_EMPLOYEE_EDUCATION, options);

  const deleteEmployeeEducation = useCallback(
    async (values: DeleteEmployeeEducationVars) => {
      try {
        await deleteEmployeeEducationMutation({
          variables: values,
          refetchQueries: [
            { query: GET_EMPLOYEE_DETAILS, variables: { employeeId } },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [deleteEmployeeEducationMutation, employeeId]
  );

  return { deleteEmployeeEducation, ...data };
};
