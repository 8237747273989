import axios, { AxiosError } from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { useCallback } from "react";
import { toast } from "react-toastify";
import useSWRMutation, { MutationFetcher } from "swr/mutation";
import { ProjectDocumentTemplate } from "types/project";

export const useGetProjectDocumentTemplates = () => {
  const { token } = useAuthContext();
  const getProjectDocuments = useCallback<
    MutationFetcher<ProjectDocumentTemplate[], string>
  >(
    async (
      url
    ) => {
      try {
        const { data } = await axios.get<ProjectDocumentTemplate[]>(
          `${url}/templates`,
          {
            headers: {
              "Authorization": token ? `Bearer ${token}` : "",
              "Content-Type": "application/json",
            },
            transformResponse: (response) => {
              const data: ProjectDocumentTemplate[] =
                JSON.parse(response || "");
              return data;
            },
          }
        );
        return data;
      } catch (e) {
        toast.error("Something went wrong.");
        throw new Error((e as AxiosError)?.message);
      }
    },
    [token]
  );

  return useSWRMutation(
    `${process.env.REACT_APP_BACKEND_URL}${API.projectDocuments}`,
    getProjectDocuments,
    { throwOnError: false }
  );
};
