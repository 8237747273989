import axios, { AxiosError } from "axios";
import API from "config/api";
import { useAuthContext } from "contexts";
import { useCallback } from "react";
import { toast } from "react-toastify";
import useSWRMutation, { MutationFetcher } from "swr/mutation";
import { EmployeeDocument } from "types/employee";

export type GetEmployeeDocumentsRequestParams = {
  employeeId: string;
  syncWithGdrive: boolean | undefined;
};

export type GetEmployeeDocumentsRequestArgs = {
  requestQueryParams: GetEmployeeDocumentsRequestParams;
  requestBody?: {
    format?: "json";
  };
};


export const useGetEmployeeDocuments = () => {
  const { token } = useAuthContext();
  const getEmployeeDocuments = useCallback<
    MutationFetcher<EmployeeDocument[], string, GetEmployeeDocumentsRequestArgs>
  >(
    async (
      url,
      {
        arg: {
          requestQueryParams: { employeeId, syncWithGdrive },
        },
      }
    ) => {
      try {
        const { data } = await axios.get<EmployeeDocument[]>(
          `${url}/${employeeId}?syncWithGdrive=${syncWithGdrive}`,
          {
            headers: {
              "Authorization": token ? `Bearer ${token}` : "",
              "Content-Type": "application/json",
            },
            transformResponse: (response) => {
              const data: EmployeeDocument[] =
                JSON.parse(response || "");
              return data;
            },
          }
        );
        return data;
      } catch (e) {
        toast.error("Something went wrong.");
        throw new Error((e as AxiosError)?.message);
      }
    },
    [token]
  );

  return useSWRMutation(
    `${process.env.REACT_APP_BACKEND_URL}${API.employeeDocuments}`,
    getEmployeeDocuments,
    { throwOnError: false }
  );
};
