import { ReactNode, useState } from "react";

type DragNDropProps<T> = {
  items: T[];
  render: (item: T, index: number) => ReactNode;
  onDragEnd: (newItems: T[]) => void;
};

export const DragNDrop = <T,>({
  items,
  render,
  onDragEnd,
}: DragNDropProps<T>) => {
  const [temporaryItems, setTemporaryItems] = useState<T[]>();
  const [draggedItem, setDraggedItem] = useState<T>();

  const renderedItems = temporaryItems || items;

  return renderedItems.map((item, index) => (
    <div
      key={index}
      draggable
      className={"cursor-grab" + (item === draggedItem ? " opacity-40" : "")}
      onDragStart={() => setDraggedItem(item)}
      onDragOver={(e) => {
        e.preventDefault();
        if (!draggedItem || draggedItem === item) {
          return;
        }
        const currentIndex = renderedItems.indexOf(draggedItem);
        const targetIndex = renderedItems.indexOf(item);

        if (currentIndex !== -1 && targetIndex !== -1) {
          const newItems = [...renderedItems];
          newItems.splice(currentIndex, 1);
          newItems.splice(targetIndex, 0, draggedItem);
          setTemporaryItems(newItems);
        }
      }}
      onDragEnd={() => {
        onDragEnd(renderedItems);
        setDraggedItem(undefined);
        setTemporaryItems(undefined);
      }}
    >
      {render(item, index)}
    </div>
  ));
};
