import React, { ForwardedRef, PropsWithChildren, forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ButtonProps } from "./Button";
import styles from "./Button.styles";

export type LinkButtonProps<D extends object> = PropsWithChildren<
  LinkProps<D> & Pick<ButtonProps, "variant">
>;
const LinkButtonInner = <D extends object>(
  { variant, children, className, ...restLinkProps }: LinkButtonProps<D>,
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Link<D>
      ref={ref}
      {...restLinkProps}
      className={twMerge(
        styles.default,
        variant && styles?.[variant],
        className
      )}
    >
      {children}
    </Link>
  );
};

export const LinkButton = forwardRef(LinkButtonInner) as <D extends object>(
  props: LinkButtonProps<D> & { ref?: ForwardedRef<HTMLAnchorElement> }
) => ReturnType<typeof LinkButtonInner>;
