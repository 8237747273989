import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Skill } from "types/skills";

type GetSkillsData = {
  skills: Skill[];
};

type HookType = QueryResult<GetSkillsData> & { data: GetSkillsData };

const GET_SKILLS = gql`
  query getSkills {
    skills: employee_skills {
      value
      name
      category
    }
  }
`;

export const useSkills = (
  options?: QueryHookOptions<GetSkillsData>
): HookType => {
  const { data, ...rest } = useQuery<GetSkillsData>(GET_SKILLS, options);

  const parsedData = useMemo<GetSkillsData>(
    () => ({
      skills: data?.skills ?? [],
    }),
    [data]
  );

  return { data: parsedData, ...rest };
};
