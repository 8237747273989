import { FC } from "react";
import { FormHelperText, InputBase, InputLabel } from "@mui/material";
import { FieldError } from "components";
import { FieldRenderProps } from "react-final-form";
import styles from "./TextControl.styles";
import { twMerge } from "tailwind-merge";

type TextControlProps = FieldRenderProps<string, HTMLElement> & {
  label?: string;
  validationIndicator?: boolean;
  placeholder?: string;
  multiline?: boolean;
  helperText?: string;
  forcedError?: string;
  classes?: {
    root?: string;
  };
  dataCy?: string;
};

export const TextControl: FC<TextControlProps> = ({
  label,
  input: { value, name, type: _, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validationIndicator = false,
  placeholder = "",
  multiline = false,
  helperText,
  forcedError,
  dataCy,
  classes,
  ...restTextField
}) => {
  const error = metaError || forcedError;
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  return (
    <div className={twMerge("my-4", classes?.root)} data-cy={dataCy}>
      <InputLabel htmlFor={name} className={styles.label}>
        {label}
      </InputLabel>
      <InputBase
        id={name}
        {...restTextField}
        fullWidth
        name={name}
        classes={{
          root: styles.root,
          focused: styles.focused,
        }}
        placeholder={placeholder}
        multiline={multiline}
        spellCheck={false}
        autoCorrect="off"
        autoCapitalize="none"
        {...restInput}
        onChange={onChange}
        value={value}
      />
      {showError && <FieldError>{error || submitError}</FieldError>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};
