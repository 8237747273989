import { FormHelperText, InputLabel } from "@mui/material";
import { FieldError } from "components";
import React, { FC, useRef } from "react";
import { FieldRenderProps } from "react-final-form";
import Select, { OnChangeValue } from "react-select";
import getStyles from "./SelectControl.styles";

export type OptionType = {
  value: string;
  label: string;
  disabled?: boolean;
  options?: OptionType[];
};

type SelectControlProps = FieldRenderProps<string[] | string, HTMLElement> & {
  label: React.ReactNode;
  allowMultiple?: boolean;
  options: Array<OptionType>;
  isLoading?: boolean;
  isError?: boolean;
  noOptionsMessage?: string;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  helperText?: string;
  blurInputOnSelect?: boolean;
  dataCy?: string;
};

export const SelectControl: FC<SelectControlProps> = ({
  label,
  allowMultiple = false,
  options,
  input: { value, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  isLoading = false,
  isError = false,
  noOptionsMessage = "No options",
  placeholder = "",
  isClearable = true,
  isDisabled,
  helperText,
  blurInputOnSelect = true,
  sortValues = true,
  dataCy,
  ...customProps
}) => {
  if (sortValues) {
    options.sort((a, b) => a.label.localeCompare(b.label));
  }
  const error = metaError || customProps.forcedError;
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;
  const controlRef = useRef<HTMLDivElement>(null);
  const styles = getStyles();

  const selectedValues = (
    allowMultiple ? (value as string[]) || [] : [value as string]
  )?.map((value) => {
    for (const option of options) {
      if (option.value === value) return option;

      if (option.options) {
        for (const nestedOption of option.options) {
          if (nestedOption.value === value) return nestedOption;
        }
      }
    }
  }) as Array<OptionType>;

  const menuPlacement = () => {
    if (!controlRef.current) {
      return "bottom";
    }
    const controlRect = controlRef.current.getBoundingClientRect();
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    const spaceBelow = viewportHeight - controlRect.bottom;
    return spaceBelow > 300 ? "bottom" : "top";
  };

  return (
    <div className="my-4">
      <>
        <InputLabel htmlFor={name} className={styles.label}>
          {label}
        </InputLabel>

        <div ref={controlRef} data-cy={dataCy}>
          <Select<OptionType, boolean>
            inputId={name}
            isMulti={allowMultiple}
            name={name}
            menuPlacement={menuPlacement()}
            blurInputOnSelect={blurInputOnSelect}
            menuPortalTarget={document.body}
            placeholder={placeholder}
            value={selectedValues}
            isClearable={isClearable}
            options={options}
            isDisabled={isDisabled}
            onChange={(selected: OnChangeValue<OptionType, boolean>) => {
              Array.isArray(selected)
                ? onChange(selected.map((s) => s.value))
                : onChange(
                    selected ? (selected as OptionType).value : undefined
                  );
            }}
            closeMenuOnSelect={!allowMultiple}
            isLoading={isError ? false : isLoading}
            classNames={{
              control: () => styles.control,
              valueContainer: () => styles.valueContainer,
              input: () => styles.inputContainer,
              menuPortal: () => styles.menuPortal,
              option: ({ isFocused, isSelected }) =>
                isSelected
                  ? styles.optionSelected
                  : isFocused
                  ? styles.optionFocused
                  : "",
              multiValueRemove: () => styles.multiValueRemove,
            }}
            noOptionsMessage={() =>
              isError ? "There is something wrong with data" : noOptionsMessage
            }
            classNamePrefix="react-select"
            {...restInput}
            {...customProps}
          />
        </div>

        {showError && <FieldError>{error || submitError}</FieldError>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </>
    </div>
  );
};
