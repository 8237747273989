import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Employee } from "types/employee";
import { getWeekState } from "utils/timesheets.utils";

interface GetEmployeesData {
  employees: Pick<
    Employee,
    "id" | "firstName" | "lastName" | "picture" | "timesheets"
  >[];
}
interface GetEmployeesVars {
  startDate: string;
  endDate: string;
  directSupervisorId?: string;
  startMonthDate: string;
  endMonthDate: string;
  employeeIds?: string[];
}

const employeeFragment = gql`
  fragment EmployeeData on employees {
    id
    firstName: first_name
    lastName: last_name
    picture
    timesheets: work_logs(
      where: { start_time: { _gte: $startDate }, end_time: { _lte: $endDate } }
    ) {
      id
      state
    }
  }
`;

const GET_EMPLOYEES_TIMESHEETS_STATE = gql`
  query employeesTimesheets(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $employeeIds: [uuid!]
    $startMonthDate: timestamptz!
    $endMonthDate: timestamptz!
  ) {
    employees(
      where: {
        _and: [
          {
            _or: [
              {
                _and: [
                  { status: { _is_null: false } }
                  { status: { _neq: ARCHIVED } }
                  { status: { _neq: CANDIDATE } }
                ]
              }
              {
                work_logs: {
                  start_time: { _gte: $startMonthDate }
                  end_time: { _lte: $endMonthDate }
                }
              }
            ]
          }
          { id: { _in: $employeeIds } }
        ]
      }
      order_by: { last_name: asc }
    ) {
      ...EmployeeData
    }
  }
  ${employeeFragment}
`;

const GET_EMPLOYEES_TIMESHEETS_STATE_BY_DIRECT_SUPERVISOR = gql`
  query employeesTimesheets(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $directSupervisorId: uuid!
    $startMonthDate: timestamptz!
    $endMonthDate: timestamptz!
  ) {
    employees(
      order_by: { last_name: asc }
      where: {
        _and: [
          { direct_supervisor_id: { _eq: $directSupervisorId } }
          {
            _or: [
              {
                _and: [
                  { status: { _is_null: false } }
                  { status: { _neq: ARCHIVED } }
                  { status: { _neq: CANDIDATE } }
                ]
              }
              {
                work_logs: {
                  start_time: { _gte: $startMonthDate }
                  end_time: { _lte: $endMonthDate }
                }
              }
            ]
          }
        ]
      }
    ) {
      ...EmployeeData
    }
  }
  ${employeeFragment}
`;

export const useEmployeesTimesheetsState = (
  options: QueryHookOptions<GetEmployeesData, GetEmployeesVars>
) => {
  const { data, loading, error } = useQuery<GetEmployeesData, GetEmployeesVars>(
    options.variables?.directSupervisorId
      ? GET_EMPLOYEES_TIMESHEETS_STATE_BY_DIRECT_SUPERVISOR
      : GET_EMPLOYEES_TIMESHEETS_STATE,
    {
      ...options,
      fetchPolicy: "no-cache",
    }
  );

  const preparedData = useMemo(
    () =>
      (data?.employees ?? []).map((employee) => {
        const timesheets = employee.timesheets ?? [];
        const weekState = getWeekState(timesheets);
        return { ...employee, weekState };
      }),
    [data?.employees]
  );

  return {
    employeesData: preparedData,
    loading,
    error,
  };
};
