import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type CreateEmployeeCertificateData = {
  response: { id: string };
};

type CreateEmployeeCertificateVars = {
  employeeId: string;
  name: string;
  date: string;
};

const CREATE_EMPLOYEE_CERTIFICATE = gql`
  mutation CreateEmployeeCertificate(
    $employeeId: uuid!
    $name: String!
    $date: timestamptz!
  ) {
    response: insert_employee_certificates_one(
      object: { employee_id: $employeeId, name: $name, date: $date }
    ) {
      id
    }
  }
`;

export const useCreateEmployeeCertificate = (
  options?: MutationHookOptions<
    CreateEmployeeCertificateData,
    CreateEmployeeCertificateVars
  >
) => {
  const [createEmployeeCertificateMutation, data] = useMutation<
    CreateEmployeeCertificateData,
    CreateEmployeeCertificateVars
  >(CREATE_EMPLOYEE_CERTIFICATE, options);

  const createEmployeeCertificate = useCallback(
    async (values: CreateEmployeeCertificateVars) => {
      try {
        await createEmployeeCertificateMutation({
          variables: values,
          refetchQueries: [
            {
              query: GET_EMPLOYEE_DETAILS,
              variables: { employeeId: values.employeeId },
            },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [createEmployeeCertificateMutation]
  );

  return { createEmployeeCertificate, ...data };
};
