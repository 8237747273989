import { useCallback } from "react";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_SKILL_ASSESSMENTS } from "hooks/useSkillAssessments/useSkillAssessments";

type DeleteSkillAssessmentData = {
  response: { skill: string };
};

type DeleteSkillAssessmentVars = {
  employeeId: string;
  skill: string;
};

const DELETE_SKILL_ASSESSMENT = gql`
  mutation deleteSkillAssessment($employeeId: uuid!, $skill: String!) {
    delete_employee_skill_assessments_by_pk(
      employee_id: $employeeId
      skill: $skill
    ) {
      skill
    }
  }
`;

export const useDeleteSkillAssessment = () => {
  const [deleteSkillAssessmentMutation, data] = useMutation<
    DeleteSkillAssessmentData,
    DeleteSkillAssessmentVars
  >(DELETE_SKILL_ASSESSMENT);

  const deleteSkillAssessment = useCallback(
    async (values: DeleteSkillAssessmentVars) => {
      try {
        await deleteSkillAssessmentMutation({
          variables: values,
          refetchQueries: [{ query: GET_SKILL_ASSESSMENTS }],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [deleteSkillAssessmentMutation]
  );

  return { deleteSkillAssessment, ...data };
};
