import { Layout, Loading } from "components";
import { VIEWS } from "config/views";
import { useAuthContext } from "contexts";
import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

const ManagerRoutes = lazy(() => import("routes/manager.routes"));
const EmployeeRoutes = lazy(() => import("routes/employee.routes"));
const UserNotFoundPage = lazy(
  () => import("pages/userNotFound/UserNotFound.page")
);
const SessionExpiredPage = lazy(
  () => import("pages/sessionExpired/SessionExpired.page")
);
const ManagerLoginPage = lazy(
  () => import("pages/managerLogin/ManagerLogin.page")
);

const App = () => {
  const { isInitialized, isManager } = useAuthContext();
  if (!isInitialized) {
    return <Loading fullScreen />;
  }
  return (
    <Suspense fallback={<Loading fullScreen />}>
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-ignore*/}
      <Switch>
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        <Route exact path={VIEWS.common.userNotFound.path}>
          <UserNotFoundPage />
        </Route>
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        <Route exact path={VIEWS.common.sessionExpired.path}>
          <SessionExpiredPage />
        </Route>
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        <Route exact path={VIEWS.common.managerLogin.path}>
          <ManagerLoginPage />
        </Route>
        <Layout>{isManager() ? <ManagerRoutes /> : <EmployeeRoutes />}</Layout>
      </Switch>
    </Suspense>
  );
};

export default App;
