import { useCallback } from "react";
import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { toast } from "react-toastify";
import { GET_EMPLOYEE_DETAILS } from "hooks/useEmployeeDetails/useEmployeeDetails";

type DeleteEmployeeExperienceData = {
  response: { id: string };
};

type DeleteEmployeeExperienceVars = {
  id: string;
};

const DELETE_EMPLOYEE_EXPERIENCE = gql`
  mutation DeleteEmpoloyeeExperience($id: uuid!) {
    response: delete_employee_experiences_by_pk(id: $id) {
      id
    }
  }
`;

export const useDeleteEmployeeExperience = (
  employeeId: string,
  options?: MutationHookOptions<
    DeleteEmployeeExperienceData,
    DeleteEmployeeExperienceVars
  >
) => {
  const [deleteEmployeeExperienceMutation, data] = useMutation<
    DeleteEmployeeExperienceData,
    DeleteEmployeeExperienceVars
  >(DELETE_EMPLOYEE_EXPERIENCE, options);

  const deleteEmployeeExperience = useCallback(
    async (values: DeleteEmployeeExperienceVars) => {
      try {
        await deleteEmployeeExperienceMutation({
          variables: values,
          refetchQueries: [
            { query: GET_EMPLOYEE_DETAILS, variables: { employeeId } },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [deleteEmployeeExperienceMutation, employeeId]
  );

  return { deleteEmployeeExperience, ...data };
};
