import { Button } from "components";
import ROUTES from "config/routes";
import { CareerPathFormProgress } from "pages/careerPathForm/components/careerPathForm/components";
import { SurveyComponentProps } from "pages/managerCareerPathForm/ManagerCareerPathForm.page";
import React, { FC } from "react";
import { Link, generatePath } from "react-router-dom";
import { Subcategory } from "types/career-path";
import {
  QuestionsFooter,
  QuestionsHeader,
  SurveyViewHeader,
  SurveyViewWrapper,
} from "..";
import {
  EvaluationQuestionsTable,
  FilteredQuestionsTable,
  InconsistentAnswers,
  ToggleSurveyView,
} from "./components";
import { useEvaluationSurvey } from "./useEvaluationSurvey";

export type EvaluationSurveyProps = SurveyComponentProps;

export const EvaluationSurvey: FC<EvaluationSurveyProps> = ({
  answers,
  careerPathConfig,
  employeeData,
}) => {
  const {
    isEvaluationSurveyPreview,
    isFormCompleted,
    showFullSurvey,
    setShowFullSurvey,
    answersGroupedByQuestionIdState,
    answersGroupedByQuestionId,
    handleManagerAnswer,
    currentSubCategory,
    setCurrentSubCategory,
    mappedSubcategories,
    isLastSubcategory,
    categoryKey,
    unresolvedQuestionsBySubcategory,
    isMobile,
  } = useEvaluationSurvey({ careerPathConfig, answers });

  return (
    <>
      {!isEvaluationSurveyPreview && (
        <SurveyViewHeader employeeData={employeeData} />
      )}
      <CareerPathFormProgress
        unresolvedQuestionsBySubcategory={unresolvedQuestionsBySubcategory}
        activeSubcategory={currentSubCategory as Subcategory}
        isFormCompleted={isFormCompleted && isLastSubcategory}
        categories={careerPathConfig?.categories ?? []}
        subcategories={careerPathConfig?.subcategories ?? []}
        disableProgress={!showFullSurvey}
      />
      <SurveyViewWrapper>
        <div className="flex max-h-full w-full max-w-240 flex-col bg-white md:mt-12 md:rounded md:border md:border-grey-5">
          {!isMobile ? (
            <QuestionsHeader employeeData={employeeData}>
              {!isEvaluationSurveyPreview && (
                <ToggleSurveyView
                  value={showFullSurvey}
                  onChange={(_, checked) => {
                    setShowFullSurvey(checked);
                    setCurrentSubCategory(
                      (categoryKey && mappedSubcategories?.[categoryKey]) ||
                        currentSubCategory
                    );
                  }}
                />
              )}
            </QuestionsHeader>
          ) : (
            <InconsistentAnswers
              subcategories={careerPathConfig?.subcategories}
              answersGroupedByQuestionId={answersGroupedByQuestionId}
              answersGroupedByQuestionIdState={answersGroupedByQuestionIdState}
            />
          )}
          {showFullSurvey ? (
            <>
              <EvaluationQuestionsTable
                answersGroupedByQuestionId={answersGroupedByQuestionId}
                answersGroupedByQuestionIdState={
                  answersGroupedByQuestionIdState
                }
                handleManagerAnswer={handleManagerAnswer}
                currentSubCategory={currentSubCategory}
                isEvaluationSurveyPreview={isEvaluationSurveyPreview}
              />
              <QuestionsFooter
                answersGroupedByQuestionIdState={
                  answersGroupedByQuestionIdState
                }
                mappedSubcategories={mappedSubcategories}
                currentSubCategory={currentSubCategory}
                setCurrentSubCategory={setCurrentSubCategory}
                isFormCompleted={isFormCompleted}
                isLastSubcategory={isLastSubcategory}
                lastStepButtonText="Complete Evaluation"
                isEvaluationSurveyPreview={isEvaluationSurveyPreview}
              />
            </>
          ) : (
            <>
              <FilteredQuestionsTable
                subcategories={careerPathConfig?.subcategories}
                answersGroupedByQuestionId={answersGroupedByQuestionId}
                answersGroupedByQuestionIdState={
                  answersGroupedByQuestionIdState
                }
                handleManagerAnswer={handleManagerAnswer}
              />
              {!isEvaluationSurveyPreview && (
                <div className="flex justify-between gap-3 bg-white px-12 py-5 shadow-[0px_-8px_8px_-6px] shadow-grey-5 xs:flex-col xs:px-5">
                  <>
                    {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                    {/*@ts-ignore*/}
                    <Link
                      to={generatePath(ROUTES.manager.careerPath)}
                      className="flex xs:grow"
                    >
                      <Button
                        variant="tertiary"
                        disabled={isFormCompleted}
                        className="xs:grow"
                      >
                        Finish later
                      </Button>
                    </Link>
                    {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                    {/*@ts-ignore*/}
                    <Link
                      to={generatePath(ROUTES.manager.careerPath)}
                      className="flex xs:grow"
                    >
                      <Button
                        variant="primary"
                        disabled={!isFormCompleted}
                        className="w-60 xs:grow"
                      >
                        Complete Evaluation
                      </Button>
                    </Link>
                  </>
                </div>
              )}
            </>
          )}
        </div>
      </SurveyViewWrapper>
    </>
  );
};
